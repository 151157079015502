<template>
  <v-form @submit.prevent="submit" v-model="valid">
    <v-sheet elevation="2" class="pa-4">
      <v-row class="fill-height">
        <v-col cols="auto">
          <p class="headline pt-3">
            <v-icon x-large class="pr-2 mt-n2" color="blue">add_location</v-icon
            >Create Site Location
          </p>
        </v-col>

        <v-spacer></v-spacer>
      </v-row>

      <v-row>
        <v-col sm="6" class="mt-3">
          <v-combobox
            :items="siteLocations.map((sl) => sl.siteLocationName)"
            v-model="siteLocationName"
            label="Site Location"
            hint="What location is this meter installed in"
            persistent-hint
            ref="cBox"
          >
            <template v-slot:append-outer>
              <v-btn
                color="primary"
                title="Add a new site location"
                @click="handleSubmit"
              >
                save
              </v-btn>
              <v-btn
                @click="handleReturn"
                class="ml-5"
              >
                cancel
              </v-btn>
            </template>
          </v-combobox>
        </v-col>
      </v-row>
    </v-sheet>
  </v-form>
</template>

<script>
import api from "../_api";

export default {
  data() {
    return {
      siteLocations: ["primary", "secondary"],
      siteId: null,
      siteLocationName: null,
      valid: true,
    };
  },

  beforeCreate() {
  },

  async created() {
    this.siteId = this.$route.params.siteId;
    try {
      await this.getLookups(this.siteId);
    } catch (error) {
      this.$toast.show("Cannot find egauge locations.", null, "error");
    }
  },

  computed: {

  },

  methods: {
    async getLookups() {
      this.siteLocations = await api.getSiteLocationsBySiteId(this.siteId);
    },
    async getSites() {},

    async handleSubmit() {
      //update the combo box -- there is a bug in vuetify that won't pass the value until blur
      this.$refs.cBox.updateSelf();

      let currentSiteLocation = {
        siteId: this.siteId,
        siteLocationName: this.siteLocationName,
      };

      try {
        await api.createSiteLocation(currentSiteLocation);
        this.handleReturn();
      } catch (error) {
        console.log(error);
      }
    },

    handleReturn() {
      this.$router.go(-1);
    },
  },
};
</script>
